define("discourse/plugins/discourse-antivirus/discourse/routes/admin-plugins-show-discourse-antivirus-stats", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAntivirusStatsRoute extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/admin/plugins/discourse-antivirus/stats");
    }
  }
  _exports.default = DiscourseAntivirusStatsRoute;
});